<template>
  <div class="awaiting-box">
    <van-nav-bar
      :title="title"
      v-if="title === '专场'"
      @click-left="onClickLeft"
      class="header"
    />
    <van-nav-bar
      v-else
      :title="title"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
      class="header"
    />
    <div class="awaiting-con">
      <div class="awaiting">
        <img src="../../assets/special/no_special.png" alt />
        <p>功能暂未开放，尽请期待</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'awaiting',
    data() {
      return {
        title: '',
      };
    },
    mounted() {
      if (this.$route.name === 'special') {
        this.title = '专场';
      } else if (this.$route.name === 'resumeLibrary') {
        this.title = '简历库';
      } else {
        this.title = '功能待开发';
      }
    },
    methods: {
      onClickLeft() {
        this.$router.go(-1);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .awaiting-box {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    .header {
      height: 44px;
    }
    .awaiting-con {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      .awaiting {
        width: 170px;
        height: 200px;
        img {
          height: 170px;
          width: 170px;
        }
        p {
          margin-top: 8px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          text-align: center;
        }
      }
    }
  }
</style>
